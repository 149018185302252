import { create } from '@oceanjs/edm';
import { useIsOverviewSelectionAllowed } from '../components/OverviewSelectionGuard';

export type OverviewSelection = 'all' | 'official' | 'owner';

const DEFAULT_SELECTION = 'all';

type OverviewStore = {
  selection: OverviewSelection | null;
  setOverviewSelection: (selection: OverviewSelection) => void;
  resetOverviewSelection: () => void;
};

const useOverviewStore = create<OverviewStore>((set) => ({
  selection: null,
  setOverviewSelection(selection) {
    return set({
      selection,
    });
  },
  resetOverviewSelection() {
    return set({
      selection: DEFAULT_SELECTION,
    });
  },
}));

/**
 * Returns true when the over selection state is not initial
 */
export function useIsOverviewSelectionAvailable() {
  return useOverviewStore((store) => store.selection !== null);
}

/**
 * Returns the current value of the overview selection.
 */
export function useOverviewSelection() {
  const isOverviewSelectionAllowed = useIsOverviewSelectionAllowed();

  // console.log('[useOverviewSelection] isOverviewSelectionAllowed', isOverviewSelectionAllowed);

  if (!isOverviewSelectionAllowed) {
    throw new Error(
      'Using useOverviewSelection without the route wrapper component OverviewSelectionGuard is not permitted as it would lead to various unnecessary renders and errors.'
    );
  }

  return useOverviewStore((store) => store.selection);
}

export function useSetOverviewSelection() {
  return useOverviewStore((store) => store.setOverviewSelection);
}

export function useResetOverviewSelection() {
  return useOverviewStore((store) => store.resetOverviewSelection);
}
