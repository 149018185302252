import { useLayoutEffect } from 'react';
import { useUserInfo } from '../../../api/hooks/useUserInfo';
import { useSetOverviewSelection } from '../../../state/overviewSlice';
import { hasValue } from '../../../utils/hasValue';

/**
 * Component that sets the default overview selection for registered or official
 * users to the official/owner view
 * See EDMSV-432
 */
export const DefaultViewSelector = () => {
  const user = useUserInfo();
  const setOverviewSelection = useSetOverviewSelection();

  const isAuthority = user?.userType === 'AUTHORITY' && hasValue(user.bundeslandCode);
  const isOwner = user?.userType === 'NATIPPC_REGISTERED';

  useLayoutEffect(() => {
    if (isAuthority) {
      setOverviewSelection('official');
    } else if (isOwner) {
      setOverviewSelection('owner');
    } else {
      setOverviewSelection('all');
    }
  }, [isAuthority, isOwner, setOverviewSelection]);

  return null;
};
