import { HttpRequest, isLocalBackend } from '@oceanjs/edm';
import { useCallback, useEffect, useRef } from 'react';
import { client } from '../../../api';

/**
 * This hook fires an explicit logout event on any network request after
 * the previous session ran into a timeout. Otherwise the application would
 * think that the user is still logged in, but the backend would respond with
 * a logged out state - leading to undefined behaviour.
 * @see EDMSV-296
 */
export function useExpiredSessionLogout() {
  const sessionExpired = useRef(false);

  const handleSessionExpired = useCallback(() => {
    sessionExpired.current = true;
  }, []);

  useEffect(() => {
    const requestInterceptor = (request: HttpRequest) => {
      if (sessionExpired.current !== true) {
        // If the session is active, do nothing
        return request;
      }

      if (isLocalBackend()) {
        // During local development reload the window to clear the state
        window.location.reload();
      } else {
        // In deployed environments redirect to the edm portal
        const targetUrl = encodeURIComponent(window.location.href);
        window.location.href = `${process.env.REACT_APP_PORTAL_URL_BASE}/home.do?event=signin&initiate&return&remoteAddr=${targetUrl}`;
      }

      return request;
    };

    client.addRequestInterceptor(requestInterceptor);
    return () => {
      client.removeRequestInterceptor(requestInterceptor);
    };
  }, []);

  return handleSessionExpired;
}
