/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Adresse {
  hausNummer?: string;
  ort?: string;
  postleitzahl?: string;
  staat?: string;
  stiege?: string;
  strasse?: string;
  tuer?: string;
}

export interface Anlage {
  alternativname?: string;
  anzuwendendeBVTSchlussfolgerungen?: AnzuwendendeBVTSchlussfolgerung[];
  /** @format date-time */
  bereitsAnEuGemeldet?: string;
  berichtseinheiten?: Berichtseinheit[];
  euAnlagenstatus?: EuAnlagenstatus;
  genehmigungsBehoerden?: Behoerde[];
  genehmigungsId: string;
  gln: string;
  hilfsstatus?: Status[];
  ippcHaupttaetigkeit?: IppcTaetigkeit;
  ippcNebentaetigkeiten?: IppcTaetigkeit[];
  koordinaten?: Koordinaten;
  kurzname?: string;
  /** @format date-time */
  letzteAenderung?: string;
  name: string;
  umweltinspektionsBehoerden?: Behoerde[];
  zusammenfassungsstatus?: Status;
}

export interface AnlageGis {
  bezirk?: Bezirk;
  genehmigungsId: string;
  koordinaten?: Koordinaten;
}

export interface AnlagenBaum {
  alternativname?: string;
  gln?: string;
  ippcHaupttaetigkeit?: IppcTaetigkeit;
  ippcNebentaetigkeiten?: IppcTaetigkeit[];
  kurzname?: string;
  name?: string;
  subAnlagen?: AnlagenBaum[];
  zusammenfassungsstatus?: Status;
}

export interface Anlagenstatus {
  beschreibung?: string;
  name: string;
}

export interface AnzuwendendeBVTSchlussfolgerung {
  bezeichnung?: string;
  code?: string;
}

export interface Behoerde {
  adresse?: Adresse[];
  ansprechPerson?: string;
  emailAdresse?: string;
  name?: string;
  telefonNummer?: string;
}

export interface Berichtseinheit {
  beschreibung?: string;
  kuerzel?: string;
}

export interface Betreiber {
  adresse?: Adresse;
  alternativname?: string;
  firmenbuchNummer?: string;
  gln: string;
  glnOeffentlicheVerwaltung?: string;
  kurzname?: string;
  name: string;
  status?: string;
}

export interface Bezirk {
  code: string;
  name?: string;
}

export interface Bundesland {
  code: string;
  name?: string;
}

export interface BundeslandMitBezirken {
  bezirke: Bezirk[];
  code: string;
  name?: string;
}

export interface BvtDmsDocument {
  /** Branche / Bezeichnung */
  branche: string;
  /** Code */
  code: string;
  /** siehe [AE](https://confluence.umweltbundesamt.at/display/ESV/Konzept+BVT+Dokumente+im+DMS#KonzeptBVTDokumenteimDMS-DMSREST-Schnittstelle)<br /><b>GTIN</b> Attribute - siehe [Liste von im DMS genutzten Datenelementen](https://test.umweltbundesamt.at/dataharmonisation/codelist/g32ivvu6qyif2afqykciqsbipdus.html) */
  dmsDocument?: DmsDocument;
}

export interface Container {
  applicationKey?: string;
  isWorkingCopy?: boolean;
  metadataKey?: string;
  sections?: Section[];
  stateInfo?: KeyField[];
  /** @format int32 */
  version?: number;
}

/** siehe [AE](https://confluence.umweltbundesamt.at/display/ESV/Konzept+BVT+Dokumente+im+DMS#KonzeptBVTDokumenteimDMS-DMSREST-Schnittstelle)<br /><b>GTIN</b> Attribute - siehe [Liste von im DMS genutzten Datenelementen](https://test.umweltbundesamt.at/dataharmonisation/codelist/g32ivvu6qyif2afqykciqsbipdus.html) */
export interface DmsDocument {
  container?: Container;
  documents?: Document[];
}

export interface Document {
  data?: Field[];
  documentId?: string;
  /** @format int64 */
  fileId?: number;
}

export interface EuAnlagenstatus {
  beschreibung?: string;
  bezeichnung?: string;
  code?: string;
}

export interface Field {
  gtin?: string;
  value?: string;
}

export interface FilterCriteriaResult {
  /** @format int64 */
  count?: number;
  filter?: string;
}

export interface FilterDTO {
  bvtConclusions: AnzuwendendeBVTSchlussfolgerung[];
  facilityStates: Anlagenstatus[];
  mainActivities: IppcTaetigkeit[];
  provinces: BundeslandMitBezirken[];
}

export interface Genehmigung {
  behoerde?: string;
  bescheidDatum?: string;
  bescheidLink?: string;
  genehmigungsId?: string;
  geschaeftszahl?: string;
  gln?: string;
  rechtsgrundlage?: string;
}

export interface Grundstueck {
  grundstuecksNummern?: string;
  katastralGemeinde?: string;
  katastralGemeindeCode?: string;
}

export interface InstallationSearchDto {
  /** ippc search criteria parameter */
  ippcSearch?: IppcSearch;
  /**
   * Nur meine Anlagen - only for registered EDM User
   * @example false
   */
  meine?: boolean;
  /**
   * only for excel endpoint - true: get offical excel, false or not set: public excel
   * @example true
   */
  official?: boolean;
  /** only for ippcInstallations endpoint - paging request parameter for installation list... */
  pageable?: PageRequestDto;
}

export interface IppcFilterRequest {
  anzuwendendeBVTSchlussfolgerungenCodeFilterCriteria?: string[];
  betreiberGln?: string[];
  bezirkFilterCriteria?: string[];
  bundeslandFilterCriteria?: string[];
  ippcHaupttaetigkeitCodeFilterCriteria?: string[];
  ippcNebentaetigkeitCodeFilterCriteria?: string[];
  uiBerichteJahrFilterCriteria?: string[];
  zusammenfassungStatusNameFilterCriteria?: string[];
}

export interface IppcFilterResult {
  anzuwendendeBVTSchlussfolgerungenFilterCriteriaResult?: FilterCriteriaResult[];
  bezirkFilterCriteriaResult?: FilterCriteriaResult[];
  bundeslandFilterCriteriaResult?: FilterCriteriaResult[];
  euAnlagenStatusFilterCriteriaResult?: FilterCriteriaResult[];
  /** @format int64 */
  gesamtAnzahlFilterCriteriaResult?: number;
  /** @format int64 */
  gesamtAnzahlWithBVTSchlussfolgerungen?: number;
  /** @format int64 */
  gesamtAnzahlWithNebentaetigkeiten?: number;
  ippcHaupttaetigkeitCodeFilterCriteriaResult?: FilterCriteriaResult[];
  ippcNebentaetigkeitCodeFilterCriteriaResult?: FilterCriteriaResult[];
  uiBerichteFilterCriteriaResult?: FilterCriteriaResult[];
  zusammenfassungStatusNameFilterCriteriaResult?: FilterCriteriaResult[];
}

export interface IppcHaupttaetigkeitCount {
  /** @format int64 */
  count: number;
  ippcHaupttaetigkeit: IppcTaetigkeit;
}

/** ippc search criteria parameter */
export interface IppcSearch {
  ippcFilterRequest?: IppcFilterRequest;
  ippcSearchRequest?: IppcSearchRequest;
}

export interface IppcSearchAndFilterResult {
  ippcFilterResult?: IppcFilterResult;
  ippcSearchResult?: PageIppcSearchResult;
}

export interface IppcSearchRequest {
  ippcSearchCriteria?: string;
}

export interface IppcSearchResult {
  anlage: Anlage;
  betreiber: Betreiber;
  standort: Standort;
  uiBericht?: UiBericht;
}

export interface IppcTaetigkeit {
  beschreibung?: string;
  blatt?: boolean;
  code?: string;
  kurzbezeichnungNeu?: string;
}

export interface KeyField {
  key?: string;
  value?: string;
}

export interface Koordinaten {
  /** @format float */
  breite?: number;
  /** @format float */
  laenge?: number;
}

export interface PageIppcSearchResult {
  content?: IppcSearchResult[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}

/** only for ippcInstallations endpoint - paging request parameter for installation list... */
export interface PageRequestDto {
  /**
   * Zero-based page index (0..N)
   * @format int32
   * @default 0
   * @example 5
   */
  number?: number;
  /**
   * The size of the page to be returned
   * @format int32
   * @default 20
   * @example 10
   */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
}

export interface PageableObject {
  /** @format int64 */
  offset?: number;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  sort?: SortObject;
  unpaged?: boolean;
}

export interface Payload {
  anlage: Anlage;
  anlagenBaum?: AnlagenBaum;
  betreiber: Betreiber;
  genehmigungsInhalte?: Genehmigung[];
  standort: Standort;
  uiBerichte?: UiBericht[];
}

export interface Section {
  fields?: Field[];
  gtin?: string;
}

export interface SortObject {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

export interface Standort {
  adresse?: Adresse;
  alternativname?: string;
  bezirk?: Bezirk;
  bundesland?: Bundesland;
  gln: string;
  grundstuecke?: Grundstueck[];
  kurzname?: string;
  name: string;
}

export interface Status {
  name?: string;
  /** @format date-time */
  wirksamVon?: string;
}

export interface UiBericht {
  aktenzahl?: string;
  berichtLink?: string;
  erstellungDatum?: string;
  erstellungJahr?: string;
  genehmigungsId?: string;
  gln?: string;
  name?: string;
}

export interface UiReportTemplate {
  /** Genehmigungs-ID der zugehörigen Anlage */
  instId: string;
  /**
   * Datum der Erstellung der Vorlage
   * @format date-time
   */
  templateDate: string;
  /** Dateiname der Vorlage */
  templateName: string;
}

export interface UserInfo {
  /**
   * nur bei Benutzer Typ AUTHORITY: Bundesland-Zuständigkeit ( Code aus REDA 3012 'Liste österreichischer Bundesländer')
   * @example "AT-1"
   */
  bundeslandCode?: string;
  /** nur relevant bei Benutzer Typ AUTHORITY: Mitgliedschaft im Team IPPC-Redakteur */
  isIppcEditor: boolean;
  /** Typ des NATIPPC Benutzers */
  userType: 'AUTHORITY' | 'NATIPPC_REGISTERED' | 'PUBLIC';
}

import { ContentType, RequestParams } from '@oceanjs/edm';

import { UbaHttpClient } from '@oceanjs/edm';

/**
 * @title IPPC Austria API
 * @version v1.0.0
 * @baseUrl http://localhost:8080
 * @externalDocs https://www.umweltbundesamt.at
 *
 * IPPC Austria Application
 */
export class Api<SecurityDataType extends unknown> {
  http: UbaHttpClient<SecurityDataType>;

  constructor(http: UbaHttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    /**
     * @description Checks, if is registered ippc & registered GLN == betreiber Gln
     *
     * @tags auth-controller
     * @name IsRegisteredForIppcInstallation
     * @summary Checks, if is registered ippc & registered GLN == betreiberGln
     * @request GET:/api/auth/isRegisteredForIppcInstallation/{betreiberGln}
     */
    isRegisteredForIppcInstallation: (betreiberGln: string, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/api/auth/isRegisteredForIppcInstallation/${betreiberGln}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get EDM user info
     *
     * @tags auth-controller
     * @name GetUserInfo
     * @summary get EDM user info
     * @request GET:/api/auth/userInfo
     */
    getUserInfo: (params: RequestParams = {}) =>
      this.http.request<UserInfo, any>({
        path: `/api/auth/userInfo`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description create new BVT document in DMS APP (AUTHORITY user only)
     *
     * @tags bvt-controller
     * @name CreateDocument
     * @summary create new BVT document (AUTHORITY user only)
     * @request GET:/api/bvt/createDocument
     */
    createDocument: (
      query: {
        bvtCode: string;
        backlink?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<string, any>({
        path: `/api/bvt/createDocument`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bvt-controller
     * @name GetFile
     * @summary get file from dms as inputstream
     * @request GET:/api/bvt/getFile/{fileId}
     */
    getFile: (fileId: number, params: RequestParams = {}) =>
      this.http.request<File, any>({
        path: `/api/bvt/getFile/${fileId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get list of BVTs with documents from DMS
     *
     * @tags bvt-controller
     * @name GetBvtList
     * @summary get list of BVTs
     * @request GET:/api/bvt/list
     */
    getBvtList: (params: RequestParams = {}) =>
      this.http.request<BvtDmsDocument[], any>({
        path: `/api/bvt/list`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description %1 Platzhalter für metadataKey, %2: backLink zur Anwendung
     *
     * @tags config-controller
     * @name GetEdmLinkTemplateBvtDmsDocument
     * @summary get EDM Link template for the existing BVT DMS Document
     * @request GET:/api/config/edmLinkTemplateBvtDmsDocument
     */
    getEdmLinkTemplateBvtDmsDocument: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/api/config/edmLinkTemplateBvtDmsDocument`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description %1 Platzhalter für Anlagen-GLN (nicht Anlagengenehmigungs-ID), %2: Ziel (Landingpage, Ortsangaben, Anlagenstatus, Taetigkeiten, Anlagenstruktur), %3: backLink zur Anwendung
     *
     * @tags config-controller
     * @name GetEdmLinkTemplateAnlageDetails
     * @summary get EDM Link template for anlage details
     * @request GET:/api/config/edmlinkTemplateAnlageDetails
     */
    getEdmLinkTemplateAnlageDetails: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/api/config/edmlinkTemplateAnlageDetails`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description <a href='https://confluence.umweltbundesamt.at/x/8wJgCg'>GIS Architektur (Confluence)</a>
     *
     * @tags config-controller
     * @name GetBaseUrlBezirkeVtTiles
     * @summary get base url for bezirke vector tiles
     * @request GET:/api/config/gisBezirkeVtUrl
     */
    getBaseUrlBezirkeVtTiles: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/api/config/gisBezirkeVtUrl`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description %1 Platzhalter für Anlagenname, %2 Platzhalter für Anlagen-GLN
     *
     * @tags config-controller
     * @name GetMailtoTemplateVorlageUiBericht
     * @summary get mailto template for Vorlage UI Bericht
     * @request GET:/api/config/mailtoTemplateVorlageUiBericht
     */
    getMailtoTemplateVorlageUiBericht: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/api/config/mailtoTemplateVorlageUiBericht`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags filter-controller
     * @name GetFilterMetadata
     * @summary get sidebar filter metadata
     * @request GET:/api/filter/metadata
     */
    getFilterMetadata: (params: RequestParams = {}) =>
      this.http.request<FilterDTO, any>({
        path: `/api/filter/metadata`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get a paged & sorted list of IPPC installations (from solr index).<br /><br />query joins all filter parameters with AND condition.<br /><br />available <b>sort</b> properties (all fields from solr schema can be used): <ul><li>anlageGenehmigungsId</li><li>anlageGln</li><li>anlageName</li><li>anzuwendendeBVTSchlussfolgerungenBezeichnung</li><li>anzuwendendeBVTSchlussfolgerungenCodeFilterCriteria</li><li>zusammenfassungStatusNameFilterCriteria</li><li>betreiberName</li><li>bezirkFilterCriteria</li><li>bundeslandFilterCriteria</li><li>ippcHaupttätigkeitBeschreibung</li><li>ippcHaupttätigkeitKurzbezeichnungNeu</li><li>standortOrt</li><li>standortPostleitzahl</li><li>standortStrasse</li><li>uiBerichteJahrFilterCriteria</li></ol>
     *
     * @tags ippc-installation-from-solr-controller
     * @name FindIppcInstallations
     * @summary find IPPC installations from solr index
     * @request POST:/api/ippcInstallations
     */
    findIppcInstallations: (data: InstallationSearchDto, params: RequestParams = {}) =>
      this.http.request<IppcSearchAndFilterResult, any>({
        path: `/api/ippcInstallations`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get the IPPC installation branchen stats
     *
     * @tags ippc-installation-from-solr-controller
     * @name GetBranchenStats
     * @summary get IPPC installation branchen stats from solr index
     * @request GET:/api/ippcInstallations/branchenStats
     */
    getBranchenStats: (
      query?: {
        /**
         * Bundesland code
         * @example "AT-1"
         */
        bundeslandFilterCriteria?: string;
        /** Nur meine Anlagen - only for registered EDM User */
        meine?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<IppcHaupttaetigkeitCount[], any>({
        path: `/api/ippcInstallations/branchenStats`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get installations as public/official excel report.<br />query joins all filter parameters with AND condition.<br /><br />(Bezirk belongs to a Bundesland:<br>if <b>bezirkFilterCriteria</b> is used as search parameter, also <b>bundeslandFilterCriteria</b> should be given)
     *
     * @tags ippc-installation-from-solr-controller
     * @name GetExcel
     * @summary get public/official excel
     * @request POST:/api/ippcInstallations/excel
     */
    getExcel: (data: InstallationSearchDto, params: RequestParams = {}) =>
      this.http.request<string[], any>({
        path: `/api/ippcInstallations/excel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get a list of IPPC installations from solr index for GIS.<br /><br />query joins all filter parameters with AND condition.
     *
     * @tags ippc-installation-from-solr-controller
     * @name FindIppcInstallationsForGis
     * @summary find IPPC installations for GIS from solr index
     * @request POST:/api/ippcInstallations/gis
     */
    findIppcInstallationsForGis: (data: InstallationSearchDto, params: RequestParams = {}) =>
      this.http.request<AnlageGis[], any>({
        path: `/api/ippcInstallations/gis`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get last update time if IPPC installations
     *
     * @tags ippc-installation-from-solr-controller
     * @name GetLastUpdateTime
     * @summary get last update time if IPPC installations
     * @request GET:/api/ippcInstallations/lastUpdate
     */
    getLastUpdateTime: (params: RequestParams = {}) =>
      this.http.request<void, string>({
        path: `/api/ippcInstallations/lastUpdate`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description get the details of a IPPC installation
     *
     * @tags ippc-installation-from-solr-controller
     * @name GetInstallationDetails
     * @summary get IPPC installation details from solr index
     * @request GET:/api/ippcInstallations/{id}
     */
    getInstallationDetails: (id: string, params: RequestParams = {}) =>
      this.http.request<Payload, any>({
        path: `/api/ippcInstallations/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get all ui reports metadata (only available for authority)
     *
     * @tags ui-report-template-controller
     * @name GetUiReportTemplates
     * @summary get ui reports (metadata)
     * @request GET:/api/uitemplate/
     */
    getUiReportTemplates: (params: RequestParams = {}) =>
      this.http.request<UiReportTemplate[], void>({
        path: `/api/uitemplate/`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get the get ui report template of a IPPC installation as word with macros (only available for authority)
     *
     * @tags ui-report-template-controller
     * @name GetUiReportTemplate
     * @summary get ui report template of the IPPC installation
     * @request GET:/api/uitemplate/{id}
     */
    getUiReportTemplate: (id: string, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/api/uitemplate/${id}`,
        method: 'GET',
        ...params,
      }),
  };
}
