import { Content, Header, ManagedApp, SidebarStyle } from '@oceanjs/edm';
import { client } from '../../api';
import { routes } from '../../routes';
import { DefaultViewSelector } from './components/DefaultViewSelector';
import { useExpiredSessionLogout } from './hooks/useExpiredSessionLogout';

export const App = () => {
  const handleSessionExpired = useExpiredSessionLogout();

  return (
    <ManagedApp
      routes={routes}
      title={process.env.REACT_APP_APPLICATION_NAME ?? 'EDM Anwendung'}
      displayTitle={process.env.REACT_APP_APPLICATION_DISPLAY_NAME}
      client={client}
      returnAfterLogin
      onSessionExpired={handleSessionExpired}
    >
      <DefaultViewSelector />
      <Header />
      <Content sidebarStyle={SidebarStyle.Default} />
    </ManagedApp>
  );
};
