import { UbaHttpClient } from '@oceanjs/edm';
import { Api } from './generated';

const endpointPrefix = process.env.REACT_APP_API_ENDPOINT_PREFIX;

export const client = new UbaHttpClient({
  baseUrl: endpointPrefix,
  baseApiParams: {
    credentials: 'include',
    mode: 'cors',
  },
});

/**
 * Intercept filter management endpoints and return empty data
 */
client.addResponseInterceptor((response, request) => {
  if (
    typeof request.input === 'string' &&
    (request.input.endsWith('edm_portal/api/userSettings/filters_installationsFilter') ||
      request.input.endsWith('edm_portal/api/userSettings/filters_ojs_selected_filter_key'))
  ) {
    response.error = [];
  }

  return response;
});

export const backend = new Api(client);
