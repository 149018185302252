import { PropsWithChildren } from '@oceanjs/edm';
import { createContext, useContext } from 'react';
import { useIsOverviewSelectionAvailable } from '../state/overviewSlice';

export type OverviewSelectionGuardPros = PropsWithChildren;

/**
 * Component that has to be use as a route wrapper component when `useOverviewSelection` is used.
 *
 * The component skips rendering until the overview selection is available which eliminates a range
 * of issues with the overview selection.
 */
export function OverviewSelectionGuard({ children }: OverviewSelectionGuardPros) {
  const isOverviewSelectionAvailable = useIsOverviewSelectionAvailable();

  // console.log(
  //   '[OverviewSelectionGuard] isOverviewSelectionAvailable',
  //   isOverviewSelectionAvailable
  // );

  if (isOverviewSelectionAvailable === false) {
    // console.log('[OverviewSelectionGuard] skip render');
    return null;
  }

  return (
    <OverviewSelectionContext.Provider value={true}>{children}</OverviewSelectionContext.Provider>
  );
}

const OverviewSelectionContext = createContext(false);

export function useIsOverviewSelectionAllowed() {
  return useContext(OverviewSelectionContext);
}
