import { createRoot } from 'react-dom/client';
import { App } from './components/App';

import '@oceanjs/edm/theme.css';

// Temporarely fixes GIS sidebar breaking the layout in mobile view, see EDMSV-531
import './gis-override.css';

// Workaround for EDMSV-831
if (sessionStorage.getItem('edmLinkBackLinkName') === 'IPPC Austria') {
  sessionStorage.removeItem('edmLinkBackLink');
  sessionStorage.removeItem('edmLinkBackLinkName');
  sessionStorage.removeItem('edmLinkNewTab');
}

createRoot(document.getElementById('root') as HTMLElement).render(<App />);
