import { useSuspenseQuery } from '@oceanjs/edm';
import { QUERY_KEY_USER_INFO } from '../../constants/queryKeys';
import { backend } from '../api';
import { UserInfo } from '../generated';

export function useUserInfo(): UserInfo | undefined {
  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY_USER_INFO],
    queryFn: async () => {
      const result = await backend.api.getUserInfo();
      return result.data;
    },
    staleTime: 5 * 60 * 1000,
  });

  return data;

  // return useMemo(() => ({ ...data, userType: 'PUBLIC' }), [data]);

  // return useMemo(() => ({ ...data, userType: 'AUTHORITY', bundeslandCode: '7' }), [data]);

  // return useMemo(() => ({ ...data, userType: 'NATIPPC_REGISTERED', bundeslandCode: '9' }), [data]);
}
