export const QUERY_KEY_INSTALLATIONS = 'installations';

export const QUERY_KEY_INSTALLATIONS_FOR_GIS = 'installationsGis';

export const QUERY_KEY_GIS_INSTALLATIONS = 'gisInstallations';

export const QUERY_KEY_INSTALLATION = 'installation';

export const QUERY_KEY_INSTALLATIONS_BY_BRANCH = 'installationsByBranch';

export const QUERY_KEY_USER_INFO = 'userInfo';

export const QUERY_KEY_INSTALLATION_OWNER = 'installationOwner';

export const QUERY_KEY_UI_REPORT_TEMPLATES = 'uiReportTemplates';

export const QUERY_KEY_EDM_LINK_TEMPLATE_ANLAGE_DETAILS = 'edmLinkTemplateAnlageDetails';

export const QUERY_KEY_MAILTO_TEMPLATE_VORLAGE_UI_BERICHT = 'mailtoTemplateVorlageUiBericht';

export const QUERY_KEY_GIS_VECTOR_TILE_URL = 'gisVtBaseUrl';

export const QUERY_KEY_BAT_DOCUMENTS = 'batDocumentListByBat';

export const QUERY_KEY_BAT_DOCUMENT_CREATE = 'batDocumentCreateLink';

export const QUERY_KEY_BAT_DOCUMENT_EDIT = 'batDocumentEditLink';
