import { Route } from '@oceanjs/edm';
import { lazy } from 'react';
import { OverviewSelectionGuard } from '../components/OverviewSelectionGuard';

export const routes: Route[] = [
  {
    name: 'Startseite',
    path: '/start',
    wrapperComponent: OverviewSelectionGuard,
    component: lazy(() =>
      import('../pages/OverviewPage').then((module) => ({
        default: module['OverviewPage'],
      }))
    ),
    routes: [],
  },
  {
    name: 'Anlagenregister',
    path: '/installations',
    wrapperComponent: OverviewSelectionGuard,
    component: lazy(() =>
      import('../pages/InstallationsPage').then((module) => ({
        default: module['InstallationsPage'],
      }))
    ),
    features: { sidebar: false },
    routes: [
      {
        name: 'Anlagendetails',
        path: '/:id',
        component: lazy(() =>
          import('../pages/InstallationDetailsPage').then((module) => ({
            default: module['InstallationDetailsPage'],
          }))
        ),
        routes: [],
        visible: false,
      },
    ],
  },
  {
    name: 'Umweltinspektion',
    path: '/environmental-inspection',
    component: lazy(() =>
      import('../pages/CmsPage').then((module) => ({
        default: module['CmsPage'],
      }))
    ),
    routes: [],
  },
  {
    name: 'bvT Schlussfolgerungen',
    path: '/bvt-conclusions',
    component: lazy(() =>
      import('../pages/BatConclusionsPage').then((module) => ({
        default: module['BatConclusionsPage'],
      }))
    ),
    routes: [],
  },
  {
    name: 'Rechtsgrundlagen/Leitfäden',
    path: '/legal-bases-guidelines',
    component: lazy(() =>
      import('../pages/CmsPage').then((module) => ({
        default: module['CmsPage'],
      }))
    ),
    routes: [],
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: lazy(() =>
      import('../pages/FaqPage').then((module) => ({
        default: module['FaqPage'],
      }))
    ),
    routes: [],
  },
];
